import styled, { keyframes } from 'styled-components'
import { Balance } from '@pancakeswap/uikit'
// import { LotteryStatus } from 'config/constants/types'
// import { useTranslation } from '@pancakeswap/localization'
import { usePriceCakeUSD } from 'state/farms/hooks'
import { useLottery } from 'state/lottery/hooks'
import { getBalanceNumber } from '@pancakeswap/utils/formatBalance'
import Image from 'next/image'
import BuyTicketsButton from './BuyTicketsButton'
import headingTicket from '../Assests/Images/tickets/headingTicket.webp'
import exbitdots from '../Assests/Images/tickets/exbitdots.webp'
import buttons from '../Assests/Images/tickets/buttons.webp'
import leftstar from '../Assests/Images/tickets/leftstar.webp'
import rightstar from '../Assests/Images/tickets/rightstar.webp'
// import ticketImage from '../../Lottery/Assests/Images/Hashfair Ticket.png'
// import Svg from "../Svg";
// import { SvgProps } from "../types";

export const floatingStarsLeft = keyframes`

  from {
    transform: translate(0,  0px);
  }
  50% {
    transform: translate(10px, 10px);
  }
  to {
    transform: translate(0, -0px);
  }
`
export const floatingStarsRight = keyframes`
  from {
    transform: translate(0,  0px);
  }
  50% {
    transform: translate(-10px, 10px);
  }
  to {
    transform: translate(0, -0px);
  }
`
const Backgroundlottery = styled.div`
  width: 100%;
   padding-left: 0px;
  padding-right: 0px;
`

// const TicketContainer = styled(Flex)``

// const PrizeTotalBalance = styled(Balance)`
//   background: ${({ theme }) => theme.colors.gradientGold};
//   -webkit-background-clip: text;
//   -webkit-text-fill-color: transparent;
// `

// const StyledBuyTicketButton = styled(BuyTicketsButton)<{ disabled: boolean }>`
//   color: #fff;
//   width: 150px;
//   height: 55px;
//   border-radius: 45px;
//   border: none;

//   // background: rgb(34,193,195);
//   // background: linear-gradient(to top, #eb7dff, #dab1ff);
//   font-weight: initial;
//   color: white;

//   cursor: pointer;
//   display: -webkit-box;
//   display: -webkit-flex;
//   display: -ms-flexbox;
//   display: flex;
//   -webkit-align-items: center;
//   -webkit-box-align: center;
//   -ms-flex-align: center;
//   align-items: center;
//   -webkit-box-pack: center;
//   -webkit-justify-content: center;
//   -ms-flex-pack: center;
//   justify-content: center;
//   &:hover {
//     opacity: 1 !important;
//   }
//   ${({ theme }) => theme.mediaQueries.xs} {
//     // width: 240px;
//   }
// `

// const ButtonWrapper = styled.div`
//   z-index: 1;
//   position: absolute;
//   top: 50%;
//   left: 50%;
//   transform: translate(-50%, -50%);
// `

// const Banner = styled.div`
//   position: relative;
// `
// const LeftDiv = styled.div`
//   position: relative;
// `

const Hero = () => {
  const {
    currentRound: { amountCollectedInCake },
  } = useLottery()

  const cakePriceBusd = usePriceCakeUSD()
  const prizeInBusd = amountCollectedInCake
  const prizeTotal = getBalanceNumber(prizeInBusd)

  const handleScroll = (targetId: string) => {
    const targetElement = document.getElementById(targetId)
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: 'smooth' })
    }
  }

  
  // const ticketBuyIsDisabled = status !== LotteryStatus.OPEN || isTransitioning

  // const getHeroHeading = () => {
  //   if (status === LotteryStatus.OPEN) {
  //     return (
  //       <>
  //         {prizeInBusd.isNaN() ? (
  //           <Skeleton my="7px" height={60} width={190} />
  //         ) : (
  //           <PrizeTotalBalance fontSize="64px" unit=" EXBIT" value={prizeTotal} mb="8px" decimals={0} />
  //         )}
  //         <Heading mb="32px" scale="lg" color="#ffffff">
  //           {t('in prizes!')}
  //         </Heading>
  //       </>
  //     )
  //   }
  //   return (
  //     <Heading mb="24px" scale="xl" color="#ffffff" textAlign="center">
  //       {t('Tickets on sale soon')}
  //     </Heading>
  //   )
  // }
  // const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {

  return (
    <>
      <Backgroundlottery>
        <div className="ticketMain">
          <div className="ticketInner">
            <Image src={headingTicket} alt='headingTicket' width={300} height={100} className='headingTicket' />
            <p className='lottery'>Lottery</p>
            <Image src={exbitdots} alt='exbitdots' width={300} height={100} className='exbitdots' />
            <div className="ticketprice">{prizeTotal.toFixed(2)} EXBIT</div>

            <Image src={buttons} alt='buttons' width={300} height={100} className='buttons'  onClick={() => handleScroll('buy-tickets')}/>
            <ul className="mt-4 space-y-2 text-white">
              <li>
                <button 
                  type="button"
                  onClick={() => handleScroll('rules')} 
                  className="cursor-pointer hover:text-yellow-400 bg-transparent border-none text-white"
                >
                  Rules
                </button>
              </li>
              <li>
                <button 
                  type="button"
                  onClick={() => handleScroll('results')} 
                  className="cursor-pointer hover:text-yellow-400 bg-transparent border-none text-white"
                >
                  Result
                </button>
              </li>
            </ul>
            <Image src={leftstar} alt='leftstar' width={300} height={100} className='leftstar starbg' />
            <Image src={rightstar} alt='rightstar' width={300} height={100} className='rightstar starbg' />
          </div>
        </div>
      </Backgroundlottery>

    </>
  )
}

export default Hero
